import React from "react";
import { Box } from "@mui/system";
import { Tooltip } from "@mui/material";
import { CHAR_TOOLTIP_TITLE } from "../../../constants/Constants";

const Stats = ({
  status,
  wpm,
  countDown,
  countDownConstant,
  statsCharCount,
  rawKeyStrokes,
}) => {
  let timeTaken = countDownConstant - countDown;
  return (
    <>
      <div className="result-time d-flex justify-content-between text-dark">
        <h5>WPM: {Math.round(wpm)}</h5>
        <h5>Time Taken: {timeTaken}</h5>
      </div>

      <Box display="flex" flexDirection="row">
        {status === "finished" && (
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-line" }}>
                {CHAR_TOOLTIP_TITLE}
              </span>
            }
          >
            <div className="container  my-3  ">
              <h5>
                <div
                  className="row  text-white"
                  style={{ background: "#0c6fb2" }}
                >
                  <div className="col border border-1 py-3 col-12 col-md-6 col-lg-6 ">
                    {" "}
                    <span>
                      Accuracy: {Math.round(statsCharCount[0])} %
                    </span>{" "}
                  </div>
                  <div className="col border border-1 py-3 col-12 col-md-6 col-lg-6">
                    {" "}
                    <span>
                      Inaccuracy: {Math.round(100 - statsCharCount[0])} %
                    </span>{" "}
                  </div>
                  <div className="w-100"></div>
                  <div className="col border border-1 py-3 col-12 col-md-6 col-lg-6 ">
                    <span className="correct-char-stats">
                      Correct words : {statsCharCount[1]}
                    </span>{" "}
                  </div>
                  <div className="col border border-1 py-3 col-12 col-md-6 col-lg-6 ">
                    <span className="incorrect-char-stats text-white ">
                      Incorrect words: {statsCharCount[2]}
                    </span>{" "}
                  </div>
                  <div className="w-100"></div>
                  <div className="col border border-1 py-3 col-12 col-md-6 col-lg-6 ">
                    <span className="missing-char-stats text-white">
                      Missing word : {statsCharCount[3]}
                    </span>{" "}
                  </div>
                  <div className="col border border-1 py-3 col-12 col-md-6 col-lg-6">
                    <span className="correct-char-stats ">
                      Total Words : {statsCharCount[4]}
                    </span>{" "}
                  </div>
                </div>{" "}
              </h5>
            
            </div>
            

          </Tooltip>
        )}
      </Box>
    </>
  );
};

export default Stats;
